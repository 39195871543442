<template>
    <el-container>
        <el-header style="background-color:#4fa7ff">
            <el-row>
                <el-col :span="1">
                    <el-icon color="white" :size="40" style="padding: 10px;">
                        <FirstAidKit />
                    </el-icon>
                </el-col>
                <el-col :span="9">
                    <div style="display:flex;align-items:center;height: 100%;">
                        <!-- <label style="padding-top:5px;padding-bottom:5px;color: white;font-size:x-large;">用药时刻编排助手</label> -->
                        <!-- <label style="padding-top:5px;padding-bottom:5px;color: white;font-size:x-large;">药学监护仪</label> -->
                        <!-- <label style="padding-top:5px;padding-bottom:5px;color: white;font-size:x-large;font-weight: bold;">用药助手——临床用药指导和辅助决策软件v1.0.0</label> -->
                        <label style="padding-top:5px;padding-bottom:5px;color: white;font-size:x-large;font-weight: bold;">用药指导信息辅助软件v1.0.0</label>
                    </div>
                </el-col>
                <el-col :span="14"></el-col>
            </el-row>
        </el-header>
        <el-main>
            <el-row :gutter="20">
                <el-col :span="8"></el-col>
                <el-col :span="8">
                    <el-form ref="userref" :model="user" label-width="120px" :rules="rules">
                        <el-form-item label="用户名">
                            <el-input maxlength="13" placeholder="请输入用户名" v-model="user.username"></el-input>
                        </el-form-item>
                        <el-form-item label="密码">
                            <el-input maxlength="16" placeholder="请输入密码" v-model="user.password" type="password" show-password></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button style="width:100%;font-weight:bold"  type="primary" @click="login" onkeyup.enter.native="enterLogin()">登录</el-button>
                            <div style="color: grey;">版本：v1.0.0</div>
                        </el-form-item>
                        <el-form-item>
                            
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="8"></el-col>
            </el-row>
        </el-main>
    </el-container>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import {ArrowLeft} from '@element-plus/icons-vue'
import {test,login,loadbasic} from '@/api/request.js'
import storage from '@/util/storage.js'
import store from '@/store'
import { ElLoading } from 'element-plus'
import { ref } from 'vue'
import pinyin from '../../../node_modules/js-pinyin/index'
import { ElMessage,ElMessageBox } from 'element-plus'

// const CHINESE_REGEX = /[^\u4E00-\u9FA5]/;
// const ALL_CHINESE_REGEX = /^[\u4E00-\u9FA5]+$/;

export default {
    components: {
        HelloWorld,
        ArrowLeft
    },
    data(){
      return{
        //用户基本信息
        user:{
          username: '',
          password: ''
        },
        rules:{
            username:[
                {
                    required: true,
                    message: "用户名不能为空",
                    trigger: "blur",
                },
                {
                    min: 2,
                    max: 13,
                    message: "用户名长度在2到13之间",
                    trigger: "blur",
                },
            ],
            password:[
                {
                    required: true,
                    message: "密码不能为空",
                    trigger: "blur",
                },
            ]
        },
      }
    },
    methods:{
        GotoHomePage(){
            this.$router.replace({name:'Home'});
        },
        login() {
            this.$refs.userref.validate((valid) => {
                if (valid) {
                    console.log(valid);
                    let tempdata = { "name": this.user.username, "password": this.user.password };
                    if(this.user.username===""){
                        ElMessage({
                            message: "用户名不能为空",
                            type: 'warning',
                        })
                        return;
                    }
                    if(this.user.password===""){
                        ElMessage({
                            message: "密码不能为空",
                            type: 'warning',
                        })
                        return;
                    }
                    test(tempdata).then(res => {
                        console.log(res);
                        if (res.data['code'] === 200) {
                            ElMessage({
                                message: res.data['message'],
                                type: 'success',
                            })
                            //登录成功 获取token 保存token
                            storage.saveTokenStorage('token', res.data['token']);
                            storage.saveTokenStorage('username', this.user.username);
                            //获取药物基本数据 登录成功后获取 不成功不获取
                            this.loadbasic();
                        } else {
                            ElMessage({
                                message: res.data['message'],
                                type: 'warning',
                            })
                        }
                    })
                } else {
                    console.log(valid);
                }
            })
        },
        async loadbasic(){
            const loading = ElLoading.service({
                lock: true,
                text: '加载药品数据中',
                background: 'rgba(0,0,0,0.7)',
            })
            try {
                debugger
                await loadbasic().then(res => {
                    if(res.data['code'] === 200){
                        console.log('成功获取药物基本数据');
                        let medinfolist = res.data['med_list'];
                        let templist = [];
                        for(let i = 0;i<medinfolist.length;i++){
                            let medinfolistitem = medinfolist[i];
                            templist.push(medinfolistitem);
                        }
                        this.MedinfosShort(templist);
                        this.BuildSuggestions();
                        //跳转首页
                        this.$router.replace('/');
                    }
                })
            } catch (e) {
                //加载数据出现异常
                console.log('从服务器上获取药物基本数据失败');
                console.log(e);
            } finally{
                loading.close()
            }
        },
        MedinfosShort(medinfolist){
            let shortcut = new Map;
            shortcut.set('开始',[0]);
            for(let i=0;i<medinfolist.length;i++){
                let medname = medinfolist[i].name;
                if(medname !==null && medname !== ""){
                    if(this.hasChinese(medname)){
                        //药名包含中文
                        if(this.isAllChinese(medname)){
                            //药名全中文的情况
                            let pinyinstr = pinyin.getCamelChars(medname).toLowerCase();
                            if(shortcut.has(pinyinstr)){
                                let tempvalue = shortcut.get(pinyinstr);
                                tempvalue.push(i);
                                shortcut.set(pinyinstr,tempvalue);
                            }else{
                                let tempvalue = [i];
                                shortcut.set(pinyinstr,tempvalue);
                            }
                            medinfolist[i].mednameshortcut = pinyinstr;
                        }else{
                            //药名部分中文
                            let unistr = "";
                            for (let j=0;j<medname.length;j++){
                                let str = medname[j];
                                if(this.hasChinese(str)){
                                    //当前字符为中文
                                    let pinyinstr = pinyin.getCamelChars(str).toLowerCase();
                                    unistr += pinyinstr;
                                }else{
                                    //当前字符不是中文
                                    unistr += str;
                                }
                            }
                            if(shortcut.has(unistr)){
                                let tempvalue = shortcut.get(unistr);
                                tempvalue.push(i);
                                shortcut.set(unistr,tempvalue);
                            }else{
                                let tempvalue = [i];
                                shortcut.set(unistr,tempvalue);
                            }
                            medinfolist[i].mednameshortcut = unistr;
                        }
                    }else{
                        //药名无中文的情况
                        if(shortcut.has(medname)){
                            let tempvalue = shortcut.get(medname);
                            tempvalue.push(i);
                            shortcut.set(medname,tempvalue);
                        }else{
                            shortcut.set(medname,[i]);
                        }
                        medinfolist[i].mednameshortcut = medname;
                    }
                }
            }
            //更新数据集
            store.commit('medinfos/setMedinfos',medinfolist);
            store.commit('medinfos/setMednameshortcut',shortcut);
            console.log("缩写注入完成");
        },
        hasChinese(input){
            //判断是否包含中文字符
            // var CHINESE_REGEX = /[^\u4E00-\u9FA5]/;
            var CHINESE_REGEX = new RegExp("[\\u4E00-\\u9FFF]+","g");
            if (input===""){
                return false;
            }
            if(CHINESE_REGEX.test(input)){
                return true;
            }
            return false;
        },
        isAllChinese(input){
            //判断是否全为中文字符
            var ALL_CHINESE_REGEX = /^[\u4E00-\u9FA5]+$/;
            if(input===""){
                return false;
            }
            return ALL_CHINESE_REGEX.test(input);
        },
        BuildSuggestions(){
            let suggestions = [];
            let medinfolist = store.state.medinfos.medinfos;
            try {
                for(let i=0;i<medinfolist.length;i++){
                    let tempmedinfolistitem = medinfolist[i];
                    if(tempmedinfolistitem.spec.indexOf("$")!=-1){
                        let tempspeclist = tempmedinfolistitem.spec.split("$");
                        for(let j=0;j<tempspeclist.length;j++){
                            let tempspec = tempspeclist[j];
                            let suggestionsitem = {
                                medinfoindex: i,
                                name: medinfolist[i].name,
                                factory: medinfolist[i].factory,
                                spec: tempspec,
                                shortcut: medinfolist[i].mednameshortcut,
                                count: medinfolist[i].count,
                                searchoption: medinfolist[i].name + '<' + medinfolist[i].mednameshortcut + '>',
                                suggestiontext:medinfolist[i].name+":"+medinfolist[i].factory+":"+tempspec+":"+medinfolist[i].count,
                            };
                            suggestions.push(suggestionsitem);
                        }
                    }else{
                        let suggestionsitem = {
                            medinfoindex: i,
                            name: medinfolist[i].name,
                            factory: medinfolist[i].factory,
                            spec: medinfolist[i].spec,
                            shortcut: medinfolist[i].mednameshortcut,
                            count: medinfolist[i].count,
                            searchoption: medinfolist[i].name + '<' + medinfolist[i].mednameshortcut + '>',
                            suggestiontext:medinfolist[i].name+":"+medinfolist[i].factory+":"+medinfolist[i].spec+":"+medinfolist[i].count,
                        };
                        suggestions.push(suggestionsitem);
                    }
                }
                store.commit("medinfos/setSuggestions",suggestions);
            } catch (error) {
                console.log(error);
            }
                
        },
        enterLogin(){
            document.onkeydown = (e) => {
                e = window.event || e;
                if(this.$route.path == '/login' && (e.code == 'Enter'||e.code=='enter')){
                    this.login();
                }
            }
        }
    },
    created(){
        this.enterLogin();
    }
}
</script>
<style scoped>
  .el-container{
    height: 100%;
    width: 100%;
  }
  .el-aside{
    background-color:#4fa7ff;
  }
  .el-menu-item{
    height: 20%;
  }
</style>